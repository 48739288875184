<template>
  <div>
    <modal-create-apikey
      :subscription-id="subscription.ID"
      @update-apikey-list="updateApikeyList"
    />

    <b-row v-if="$route.meta.pageTitle" class="content-header mt-2">
      <!-- Content Left -->
      <b-col class="content-header-left mb-2" cols="12" md="9">
        <b-row class="breadcrumbs-top">
          <b-col cols="12">
            <h2 class="content-header-title pr-1 mb-0">
              {{ $route.meta.pageTitle }}
            </h2>
          </b-col>
        </b-row>
      </b-col>
    </b-row>

    <b-tabs>
      <b-tab active v-if="productCode === 'realestate'">
        <template #title>
          <feather-icon icon="WindIcon" />
          <span>Flux</span>
        </template>

        <api-details-card :subscription="subscription" />
      </b-tab>
      <b-tab>
        <template #title>
          <feather-icon icon="KeyIcon" />
          <span>Accés</span>
        </template>

        <b-row class="match-height mt-1">
          <b-col cols="2">
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="primary"
              v-b-modal.modal-create-apikey
              class="ml-2 mb-2"
            >
              <feather-icon icon="PlusIcon" class="mr-50" />
              <span>Nouvelle clef d'API</span>
            </b-button>
          </b-col>
        </b-row>

        <b-row>
          <b-col cols="12" xl="8" lg="8" md="7">
            <apikey-list-array-view
              ref="apikeyList"
              :subscription-id="subscription.ID"
              @apikey-selected="apikeySelected"
            />
          </b-col>
          <b-col cols="12" xl="4" lg="8" md="7" v-if="selectedApikey">
            <apikey-card-edit :selected-apikey="selectedApikey" />
          </b-col>
        </b-row>
      </b-tab>
      <b-tab>
        <template #title>
          <feather-icon icon="ActivityIcon" />
          <span>Utilisation</span>
        </template>

        <api-metrics-card
          :subscription-id="subscription.ID"
          :product-code="productCode"
        />
      </b-tab>
    </b-tabs>
  </div>
</template>

<script>
import Ripple from "vue-ripple-directive";
import { VBModal, BRow, BCol, BButton, BTabs, BTab } from "bootstrap-vue";
import { useRouter } from "@core/utils/utils";

// Components
import ApiDetailsCard from "@/views/api-details/ApiDetailsCard.vue";
import ApikeyListArrayView from "@/views/apikeys/apikey-list/ApikeyListArrayView.vue";
import ModalCreateApikey from "@/views/apikeys/apikey-modal-create/ModalCreateApikey.vue";
import ApikeyCardEdit from "@/views/apikeys/apikey-card-edit/ApikeyCardEdit.vue";
import ApiMetricsCard from "@/views/api-metrics/ApiMetricsCard.vue";

export default {
  components: {
    BButton,
    BRow,
    BCol,
    BTabs,
    BTab,

    // Local Components
    ApiDetailsCard,
    ApikeyListArrayView,
    ModalCreateApikey,
    ApikeyCardEdit,
    ApiMetricsCard,
  },
  directives: {
    "b-modal": VBModal,
    Ripple,
  },
  props: {
    subscription: {
      type: Object,
      required: true,
    },
  },
  setup() {
    const { route } = useRouter();
    const productCode = route.value.name.split("-")[0];

    return {
      productCode,
    };
  },
  data() {
    return {
      selectedApikey: null,
    };
  },
  methods: {
    apikeySelected(apikey) {
      this.selectedApikey = apikey;
    },
    updateApikeyList() {
      this.$refs.apikeyList.updateApikeys();
    },
  },
};
</script>

<style></style>
