<template>
  <div>
    <b-overlay :show="loading" no-wrap opacity="0.4" rounded="lg">
      <template v-slot:overlay>
        <div class="d-flex align-items-center">
          <b-spinner small type="grow" variant="dark" />
          <b-spinner type="grow" variant="primary" />
          <b-spinner small type="grow" variant="dark" />
          <!-- We add an SR only text for screen readers -->
          <span class="sr-only">Chargement wait...</span>
        </div>
      </template>
    </b-overlay>

    <preview
      v-if="!loading && subscription === null"
      :productSelected="productSelected"
    />

    <board
      v-if="!loading && subscription !== null"
      :subscription="subscription"
    />
  </div>
</template>

<script>
import { PageBoxed } from "@/views/utils/PageBoxed.js";
import { BOverlay, BSpinner } from "bootstrap-vue";
import { useRouter } from "@core/utils/utils";

// Components
import Preview from "./Preview";
import Board from "./Board";

export default {
  components: {
    BOverlay,
    BSpinner,

    Preview,
    Board,
  },
  mixins: [PageBoxed],
  watch: {
    route(val) {
      this.product = this.$store.state.products.products.find(
        (el) => el.code.toLowerCase().search(val.name.split("-")[0]) !== -1
      );
      const invoiceAccountID =
        this.$store.state.invoiceAccounts.selectedInvoiceAccount.ID;

      this.syncSubscription(this.product.ID, invoiceAccountID);
    },
  },
  computed: {
    productSelected() {
      const product = this.$store.state.products.products.find(
        (el) =>
          el.code.toLowerCase().search(this.route.name.split("-")[0]) !== -1
      );

      this.syncSubscription(
        product.ID,
        this.$store.state.invoiceAccounts.selectedInvoiceAccount.ID
      );

      return product;
    },
  },
  data() {
    return {
      loading: null,
      subscription: null,
    };
  },
  setup() {
    const { route } = useRouter();
    return {
      route,
    };
  },
  methods: {
    syncSubscription(productID, invoiceAccountID) {
      this.loading = true;
      this.$store
        .dispatch("products/findSubscriptionsByProductIDAndInvoiceAccountID", {
          productID,
          invoiceAccountID,
        })
        .then((response) => {
          this.loading = false;
          if (response && response.data.length > 0) {
            this.subscription = response.data[0];
          } else {
            this.subscription = null;
          }
        })
        .catch(() => {
          this.loading = false;
        });
    },
  },
};
</script>
