<template>
  <div class="mt-2">
    <!-- search input -->
    <section>
      <b-card
        no-body
        class="knowledge-base-bg text-left"
        :style="{
          height: '23rem',
          backgroundRepeat: 'no-repeat',
          backgroundPosition: 'right',
          backgroundSize: 'contain',
          backgroundImage: `url(${publicPath}images/products/${productSelected.logo})`,
        }"
      >
        <b-card-body class="card-body">
          <h1 class="text-primary">
            {{ productSelected.name }}
          </h1>
          <b-card-text class="mb-2">
            <span class="font-weight-bolder">{{
              productSelected.description
            }}</span>
          </b-card-text>
          <b-button
            v-ripple.400="'rgba(113, 102, 240, 0.15)'"
            v-b-modal.modal-starter
            variant="primary"
          >
            Premiers pas
            <feather-icon icon="CoffeeIcon" class="ml-50" />
          </b-button>
        </b-card-body>
      </b-card>
    </section>

    <!--/ modal subscribe -->
    <starter-modal :productID="productSelected.ID" />

    <section id="knowledge-base-content">
      <!-- content -->
      <b-row class="kb-search-content-info match-height">
        <b-col md="6" sm="6" class="kb-search-content">
          <b-card
            class="text-center cursor-pointer itemgrid-card"
            :img-src="
              require('@/assets/images/illustration/personalization.svg')
            "
            :img-alt="productSelected.name"
            img-top
            @click="newTabTo(productSelected.details_link)"
          >
            <h4>Présentation</h4>
            <b-card-text class="mt-1">
              Aperçu global & détails des cas d'usages.
            </b-card-text>
          </b-card>
        </b-col>
        <b-col md="6" sm="6" class="kb-search-content">
          <b-card
            class="text-center cursor-pointer itemgrid-card"
            :img-src="require('@/assets/images/illustration/api.svg')"
            :img-alt="productSelected.name"
            img-top
            @click="
              $router.push({
                name: redirectNameBoard + '-redoc',
                query: { jsonName: productSelected.documentation_link },
              })
            "
          >
            <h4>Documentation</h4>
            <b-card-text class="mt-1">
              Accédez au contrat d'interface pour découvrir les fonctionnalités
              disponibles.
            </b-card-text>
          </b-card>
        </b-col>
      </b-row>
    </section>
  </div>
</template>

<script>
import {
  VBModal,
  BButton,
  BRow,
  BCol,
  BCard,
  BCardBody,
  BCardText,
} from "bootstrap-vue";
import Ripple from "vue-ripple-directive";
import { useRouter } from "@core/utils/utils";
import StarterModal from "./modal-starter/StarterModal.vue";

export default {
  components: {
    BButton,
    BRow,
    BCol,
    BCard,
    BCardBody,
    BCardText,
    StarterModal,
  },
  directives: {
    "b-modal": VBModal,
    Ripple,
  },
  props: {
    productSelected: {
      type: Object,
      required: true,
    },
  },
  computed: {
    redirectNameBoard() {
      const { route } = useRouter();

      return route.value.name.split("-")[0];
    },
  },
  data() {
    return {
      publicPath: process.env.BASE_URL,
    };
  },
  methods: {
    newTabTo(pageRedirectURL) {
      window.open(pageRedirectURL);
    },
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/pages/page-knowledge-base.scss";

.itemgrid-card {
  align-items: center !important;
  &:hover {
    transform: translateY(-5px);
    box-shadow: 0 4px 25px 0 rgba($black, 0.25);
  }
}
</style>
