<template>
  <b-row align-h="center">
    <b-col md="10">
      <b-card class="mx-5 flow-height" title="Flux ouverts">
        <b-list-group-item
          v-for="(listItem, index) in listFlowEnabled"
          :key="index"
          tag="li"
        >
          <div class="d-flex">
            <b-avatar
              size="20"
              :src="`${publicPath}` + `images/flux/` + `${listItem.logo}`"
              :text="listItem.name.slice(0, 2)"
            />
            <div class="ml-25">
              <b-card-text class="mb-0 font-weight-bold">
                {{ listItem.name }}
                <b-badge
                  :variant="
                    listItem.status === 'CREATED' ||
                    listItem.status === 'ENABLED'
                      ? 'light-primary'
                      : 'light-warning'
                  "
                >
                  {{ listItem.status }}
                </b-badge>
                <b-badge
                  :variant="
                    listItem.type_offers === 'NEUF'
                      ? 'light-info'
                      : 'light-secondary'
                  "
                >
                  {{ listItem.type_offers }}
                </b-badge>
              </b-card-text>
            </div>
          </div>
        </b-list-group-item>
      </b-card>
    </b-col>
  </b-row>
</template>

<script>
import store from "@/store";
import {
  BCard,
  BListGroupItem,
  BAvatar,
  BRow,
  BCol,
  BCardText,
  BBadge,
} from "bootstrap-vue";
import { onUnmounted } from "@vue/composition-api";

import apiDetailsStoreModule from "./apiDetailsStoreModule";
import useApiDetails from "./useApiDetails";

export default {
  components: {
    BCard,
    BListGroupItem,
    BAvatar,
    BRow,
    BCol,
    BCardText,
    BBadge,
  },
  props: {
    subscription: {
      type: Object,
      required: true,
    },
  },
  setup(props) {
    const API_DETAILS_APP_STORE_MODULE_NAME = "app-api-details";

    // Register module
    if (!store.hasModule(API_DETAILS_APP_STORE_MODULE_NAME))
      store.registerModule(
        API_DETAILS_APP_STORE_MODULE_NAME,
        apiDetailsStoreModule
      );

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(API_DETAILS_APP_STORE_MODULE_NAME))
        store.unregisterModule(API_DETAILS_APP_STORE_MODULE_NAME);
    });

    const {
      findFlows,
      listFlowEnabled,
      perPage,
      currentPage,
      total,

      subscriptionId,
    } = useApiDetails();

    subscriptionId.value = props.subscription.ID;
    findFlows();

    return {
      findFlows,
      listFlowEnabled,
      perPage,
      currentPage,
      total,
    };
  },
  data() {
    return {
      publicPath: process.env.BASE_URL,
    };
  },
};
</script>

<style scoped>
.flow-height {
  background: white;
  height: 45rem;
  overflow: auto;
}
</style>
