<template>
  <b-modal
    id="modal-starter"
    centered
    title="Lancez votre projet !  🛸 "
    ok-only
    ok-title="Retour"
  >
    <b-card-text>
      Veuillez contacter les services 'Cadre de Vie' (contact@cadredevie.fr)
      pour pouvoir accéder à la gestion de l'API :
    </b-card-text>

    <b-list-group class="list-group-circle text-left">
      <b-list-group-item> Création des jetons d'accès </b-list-group-item>
      <b-list-group-item>
        Visualisation graphique de l'utilisation de l'API
      </b-list-group-item>
      <b-list-group-item> Sécurisation et filtrage </b-list-group-item>
    </b-list-group>
  </b-modal>
</template>

<script>
import { BCardText, BListGroup, BListGroupItem, BModal } from "bootstrap-vue";

export default {
  components: {
    BCardText,
    BListGroup,
    BListGroupItem,
    BModal,
  },
};
</script>

<style lang="scss"></style>
