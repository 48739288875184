var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"mt-2"},[_c('section',[_c('b-card',{staticClass:"knowledge-base-bg text-left",style:({
        height: '23rem',
        backgroundRepeat: 'no-repeat',
        backgroundPosition: 'right',
        backgroundSize: 'contain',
        backgroundImage: ("url(" + _vm.publicPath + "images/products/" + (_vm.productSelected.logo) + ")"),
      }),attrs:{"no-body":""}},[_c('b-card-body',{staticClass:"card-body"},[_c('h1',{staticClass:"text-primary"},[_vm._v(" "+_vm._s(_vm.productSelected.name)+" ")]),_c('b-card-text',{staticClass:"mb-2"},[_c('span',{staticClass:"font-weight-bolder"},[_vm._v(_vm._s(_vm.productSelected.description))])]),_c('b-button',{directives:[{name:"ripple",rawName:"v-ripple.400",value:('rgba(113, 102, 240, 0.15)'),expression:"'rgba(113, 102, 240, 0.15)'",modifiers:{"400":true}},{name:"b-modal",rawName:"v-b-modal.modal-starter",modifiers:{"modal-starter":true}}],attrs:{"variant":"primary"}},[_vm._v(" Premiers pas "),_c('feather-icon',{staticClass:"ml-50",attrs:{"icon":"CoffeeIcon"}})],1)],1)],1)],1),_c('starter-modal',{attrs:{"productID":_vm.productSelected.ID}}),_c('section',{attrs:{"id":"knowledge-base-content"}},[_c('b-row',{staticClass:"kb-search-content-info match-height"},[_c('b-col',{staticClass:"kb-search-content",attrs:{"md":"6","sm":"6"}},[_c('b-card',{staticClass:"text-center cursor-pointer itemgrid-card",attrs:{"img-src":require('@/assets/images/illustration/personalization.svg'),"img-alt":_vm.productSelected.name,"img-top":""},on:{"click":function($event){return _vm.newTabTo(_vm.productSelected.details_link)}}},[_c('h4',[_vm._v("Présentation")]),_c('b-card-text',{staticClass:"mt-1"},[_vm._v(" Aperçu global & détails des cas d'usages. ")])],1)],1),_c('b-col',{staticClass:"kb-search-content",attrs:{"md":"6","sm":"6"}},[_c('b-card',{staticClass:"text-center cursor-pointer itemgrid-card",attrs:{"img-src":require('@/assets/images/illustration/api.svg'),"img-alt":_vm.productSelected.name,"img-top":""},on:{"click":function($event){return _vm.$router.push({
              name: _vm.redirectNameBoard + '-redoc',
              query: { jsonName: _vm.productSelected.documentation_link },
            })}}},[_c('h4',[_vm._v("Documentation")]),_c('b-card-text',{staticClass:"mt-1"},[_vm._v(" Accédez au contrat d'interface pour découvrir les fonctionnalités disponibles. ")])],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }