import { ref } from "@vue/composition-api";
// @ts-ignore
import store from "@/store";
// Notification
import { useToast } from "vue-toastification/composition";
// @ts-ignore
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";

export default function useApiDetails() {
  // Use toast
  const toast = useToast();

  const listFlowEnabled = ref([]);

  // Table Handlers
  const subscriptionId = ref("");
  const perPage = ref(50);
  const total = ref(0);
  const currentPage = ref(1);
  const sortBy = ref("name");
  const isSortDirDesc = ref(false);

  const findFlows = () => {
    store
      .dispatch("app-api-details/findFlowsBySubscriptionID", {
        subscriptionID: subscriptionId.value,
        perPage: perPage.value,
        page: currentPage.value,
        sortBy: sortBy.value,
        sortDesc: isSortDirDesc.value,
      })
      .then((response) => {
        listFlowEnabled.value = response.data.flows;
      })
      .catch(() => {
        toast({
          component: ToastificationContent,
          props: {
            title: "Error fetching flows enabled' list",
            icon: "AlertTriangleIcon",
            variant: "danger",
          },
        });
      });
  };

  return {
    findFlows,
    listFlowEnabled,
    perPage,
    currentPage,
    total,

    subscriptionId,
  };
}
